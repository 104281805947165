import { changeOpacity, getCookie, setCookie } from "./utils";

function consentOpenModal() {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style["overflow"] = "hidden";
  document.body.style["padding-right"] = `${scrollbarWidth}px`;

  const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
  if (headerNoticeWrap) {
    headerNoticeWrap.style["padding-right"] = `${scrollbarWidth}px`;
  }

  const modal = document.getElementById("CookiesConsent");

  modal.classList.remove("hidden");
  changeOpacity("CookiesConsent__bg", 100);

  return false;
}

window.consentOpenModal = consentOpenModal;

function consentCloseModal() {
  document.body.style["overflow"] = "visible";
  document.body.style["padding-right"] = "0px";
  const headerNoticeWrap = document.getElementById("HeaderNoticeWrap");
  if (headerNoticeWrap) {
    headerNoticeWrap.style["padding-right"] = "0px";
  }

  const modal_bg = document.getElementById("CookiesConsent__bg");
  modal_bg.style["opacity"] = 0;

  setTimeout(function () {
    document.getElementById("CookiesConsent").classList.add("hidden");
  }, 200);

  return false;
}

function consentShowRequiredHint() {
  const required_hint = document.getElementById("consent_required_hint");
  required_hint.classList.remove("hidden");
}

window.consentShowRequiredHint = consentShowRequiredHint;

function consentUpdatePreferences() {
  const marketingId = document.getElementById("marketing_cookies");
  const analyticsId = document.getElementById("analytics_cookies");
  const adUserDataId = document.getElementById("ad_user_data_cookies");
  const adPersonalization = document.getElementById("ad_personalization_cookies");

  const marketing = marketingId.classList.contains("justify-end");
  const analytics = analyticsId.classList.contains("justify-end");
  const adUserData = adUserDataId.classList.contains("justify-end");
  const adPersonalizationValue = adPersonalization.classList.contains("justify-end");

  consentSave(marketing, analytics, adUserData, adPersonalizationValue);

  const btn = document.getElementById("consent_btn");
  const flash = document.getElementById("consent_flash");

  btn.classList.add("opacity-0");

  window.setTimeout(() => {
    btn.style["display"] = "none";
    flash.classList.remove("opacity-0", "hidden");
  }, 350);

  window.setTimeout(() => {
    btn.style["display"] = "inline-block";
    flash.classList.add("opacity-0", "hidden");
  }, 3000);

  window.setTimeout(() => {
    btn.classList.remove("opacity-0");
  }, 3300);
}

window.consentUpdatePreferences = consentUpdatePreferences;

function consentSave(marketing, statistics, adUserData, adPersonalization) {
  gtag("consent", "update", {
    ad_storage: marketing ? "granted" : "denied",
    analytics_storage: statistics ? "granted" : "denied",
    ad_user_data: adUserData ? "granted" : "denied",
    ad_personalization: adPersonalization ? "granted" : "denied",
  });

  fbq("consent", marketing ? "grant" : "revoke");

  var retargetingConf = {
    rtgId: 16594,
    consent: marketing ? 1 : 0,
  };

  if (window.rc && window.rc.retargetingHit) {
    window.rc.retargetingHit(retargetingConf);
  }

  const cookieConsent = {
    necessary: true,
    marketing,
    statistics,
    ad_user_data: adUserData,
    ad_personalization: adPersonalization,
    utc: new Date().valueOf(),
  };

  setCookie("CookieConsent", JSON.stringify(cookieConsent), 1000);
}

function consentOptAll() {
  consentSave(true, true, true, true);
  consentCloseModal();
}

window.consentOptAll = consentOptAll;

function consentCheck() {
  if (
    ["/cookie-policy", "/projektove-2/cookie"].includes(
      window.location.pathname
    )
  ) {
    return false;
  }

  const consentStr = getCookie("CookieConsent");

  if (consentStr === undefined) {
    consentOpenModal();
  } else {
    const consent = JSON.parse(consentStr);
    const months = consent.marketing && consent.statistics ? 12 : 6;
    const today = new Date().valueOf();

    if (consent.utc + 1000 * 60 * 60 * 24 * 30 * months < today) {
      consentOpenModal();
    }
  }
}

consentCheck();
